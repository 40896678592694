import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import app from './app'
import auth from './auth'
import user from './user'
import customer from './customer'
import products from './products'
import favorites from './favorites'
import purchases from './purchases'
import machineries from './machineries'
import purchaseDetails from './purchase-details'
import mediaProducts from './media-products'
import machineryProducts from './machinery-products'
import branches from './branches'
import machineryBrands from './machinery-brands'
import machinerySeries from './machinery-series'
import purchaseStatus from './purchase-status'
import relatedProducts from './related-products'
import promotionProducts from './promotion-products'
import deliveryAddresses from './delivery-address'
import invoice from './invoice'
import modules from './modules'
import shoppingCart from './shopping-cart'
import warehouse from './warehouse'
import promotion from './promotion'
import categories from './categories'
import brands from './brands'
import countryMachineries from './country-machineries'
import productFeatures from './product-features'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      app,
      auth,
      user,
      customer,
      products,
      favorites,
      purchases,
      machineries,
      purchaseDetails,
      mediaProducts,
      branches,
      purchaseStatus,
      machineryProducts,
      machineryBrands,
      machinerySeries,
      relatedProducts,
      promotionProducts,
      deliveryAddresses,
      invoice,
      modules,
      shoppingCart,
      warehouse,
      promotion,
      categories,
      brands,
      countryMachineries,
      productFeatures
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
