export function cart(state) {
  return state.cart
}

export function cartBranch(state) {
  return state.cartBranch
}

export function cartOthers(state) {
  return state.cartOthers
}

export function totalCartBranch(state) {
  return state.totalCartBranch
}

export function totalCartOthers(state) {
  return state.totalCartOthers
}

export function stock(state) {
  return state.stock
}