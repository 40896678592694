import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/promotions',
      params: queryParams,
      method: 'GET',
    })
    ctx.commit('items', response.data)
    return response
  } catch (e) {
    return e
  }
}