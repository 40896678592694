export function cart(state, data) {
  state.cart = data
}

export function cartBranch(state, data) {
  state.cartBranch = data
}

export function cartOthers(state, data) {
  state.cartOthers = data
}

export function totalCartBranch(state, data) {
  state.totalCartBranch = data
}

export function totalCartOthers(state, data) {
  state.totalCartOthers = data
}

export function stock(state, data) {
  state.stock = data
}
