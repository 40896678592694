export default function () {
  return {
    item: {},
    items: [],
    pagination: {},
    gallery: [],
    download: [],
    features: []
  }
}
