export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function tree(state, data) {
  state.tree = data
}

export function menuCategory(state, data) {
  state.menuCategory = data
}
export function clean(state, data) {
  state.clean = data
}
export function allCategories(state, data) {
  state.allCategories = data
}
export function isClean(state, data) {
  state.isClean = data
}
