
const routes = [
  {
    path: '/auth',
    component: () => import('src/layouts/login-layout.vue'),
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('pages/auth/index.vue')
      },
      {
        path: 'registro',
        name: 'register',
        component: () => import('pages/auth/register.vue')
      },
      {
        path: 'verify-account/:token',
        name: 'verify',
        component: () => import('pages/auth/verify-account.vue')
      },
      {
        path: 'reset-password/:token',
        name: 'reset-password',
        component: () => import('pages/auth/reset-password.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('src/layouts/main-layout.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('src/pages/index-page.vue')
      },
      {
        path: 'productos',
        name: 'products',
        component: () => import('src/pages/products/index.vue')
      },
      {
        path: 'productos/:id',
        name: 'product-detail',
        component: () => import('src/pages/products/detail.vue')
      },
      {
        path: 'modelos',
        name: 'models',
        component: () => import('src/pages/models/index.vue')
      },
      {
        path: 'modelos/:id',
        name: 'model-detail',
        component: () => import('src/pages/models/detail.vue')
      },
      {
        path: 'confirmacion',
        name: 'order-confirmation',
        component: () => import('src/pages/order-confirmation.vue')
      },
      {
        path: 'finalizar-compra',
        name: 'finish-purchase',
        component: () => import('src/pages/finish-purchase.vue')
      },
      {
        path: 'perfil',
        component: () => import('src/pages/profile/index.vue'),
        children: [
          {
            path: 'mis-compras',
            name: 'my-shopping',
            component: () => import('src/pages/profile/my-shopping.vue')
          },
          {
            path: 'favoritos',
            name: 'favorites',
            component: () => import('src/pages/profile/my-favorites.vue')
          },
          {
            path: 'mis-direcciones-de-envio',
            name: 'shipping',
            component: () =>
              import('src/pages/profile/my-shipping-addresses.vue')
          },
          {
            path: 'datos-de-facturacion',
            name: 'billing',
            component: () => import('src/pages/profile/billing-information.vue')
          }
        ]
      },
      {
        path: 'mis-compras/:id',
        name: 'my-shopping-detail',
        component: () => import('src/pages/my-shopping-detail.vue')
      },
      {
        path: 'mi-carrito',
        name: 'my-shopping-cart',
        component: () => import('src/pages/my-shopping-cart.vue')
      },
      {
        path: 'terminos-y-condiciones',
        name: 'terms-conditions',
        component: () => import('src/pages/terms-conditions.vue')
      },
      {
        path: 'politicas-de-privacidad',
        name: 'privacy-policies',
        component: () => import('pages/privacy-policies.vue')
      }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('src/pages/error-404.vue')
  }
]

export default routes
