import { api } from 'boot/axios.js'

export async function get(ctx, data) {
  try {
    const response = await api({
      url: `/promotions/${data}`,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: `/promotion-products`,
      params: queryParams,
      method: 'GET',
    })
    ctx.commit('items', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function getPromotionProduct(ctx, queryParams) {
  try {
    const response = await api({
      // url: `/promotion-products/list?promotionId=${data}&with=image`,
      url: `/promotion-products/list`,
      params: queryParams,
      method: 'GET',
    })
    ctx.commit('promotionProducts', response.data)
    ctx.commit('promotionProductsPagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}