<template>
  <div class="ms-sidebar-cart">
    <transition name="fade">
      <div
        v-if="sidebarCart"
        class="fade"
        @click="$store.dispatch('app/sidebarCart', false)"
      />
    </transition>
    <transition name="translate">
      <div v-if="sidebarCart" class="sidebar">
        <div class="sidebar-content">
          <div
            class="sidebar-header"
            :class="{
              'ios-padding': $q.platform.is.ios
            }"
          >
            <q-btn
              dense
              no-caps
              unelevated
              flat
              color="primary"
              class="btn-close"
              @click="$store.dispatch('app/sidebarCart', false)"
            >
              <i class="icon-arrow-left-2" />
              Seguir comprando
            </q-btn>
            <p><b>Mi carrito</b> ({{ getTotalProducts() }} artículos)</p>
          </div>
          <div class="sidebar-cart-list">
            <ms-sidebar-cart-item
              v-for="(item, index) of cart"
              :key="item.product.name"
              :index="index"
              :data="item"
              @val="saveChangeCart"
            />
          </div>
        </div>
        <div class="sidebar-footer">
          <div class="actions">
            <q-btn
              unelevated
              no-caps
              dense
              color="primary"
              label="Continuar con la compra"
              class="btn next"
              :disabled="!cart.length > 0"
              @click="purchase"
            />
            <q-btn
              unelevated
              no-caps
              dense
              label="Cerrar"
              class="btn prev"
              @click="$store.dispatch('app/sidebarCart', false)"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 *
 * MsSidebarCart Component
 *
 * @author Emmanuel Hernández
 * @copyright 2021, Marciano Studio S.C.
 */
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { LocalStorage } from 'quasar'

import MsSidebarCartItem from 'components/_shared/ms-sidebar-cart-item.vue'
export default {
  name: 'MsSidebarCart',
  components: {
    MsSidebarCartItem
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const $q = useQuasar()

    const sidebarCart = computed(() => store.getters['app/sidebarCart'])
    const cart = computed(() => store.getters['shoppingCart/cart'])
    const me = computed(() => store.getters['user/me'])
    const isAuthenticated = computed(
      () => store.getters['auth/isAuthenticated']
    )

    onMounted(async () => {
      if (LocalStorage.getItem('shoppingCart')) {
        await store.dispatch(
          'shoppingCart/save',
          LocalStorage.getItem('shoppingCart')
        )
      }
    })

    const purchase = async () => {
      if (isAuthenticated.value) {
        store.dispatch('app/sidebarCart', false)
        router.push({ name: 'my-shopping-cart' })
      } else {
        store.dispatch('app/dialogLogin', true)
      }
    }

    const saveChangeCart = async data => {
      try {
        let cloneCart = [...cart.value]
        let clone = []
        for (let index = 0; index < cloneCart.length; index++) {
          if (cloneCart[index].product.id == data.id) {
            clone.push({
              id: data.id,
              product: data.product,
              quantity: data.quantity,
              url: data.url,
              promotionId: data.promotionId
            })
          } else {
            clone.push({
              id: cloneCart[index].id,
              product: cloneCart[index].product,
              quantity: cloneCart[index].quantity,
              url: cloneCart[index].url,
              promotionId: cloneCart[index].promotionId
            })
          }
        }
        await store.dispatch('shoppingCart/save', clone)
        LocalStorage.set('shoppingCart', clone)
      } catch (e) {
        throw e
      }
    }

    const deleteProductCart = async indexProduct => {
      try {
        let clone = []
        for (let index = 0; index < cart.value.length; index++) {
          if (cart.value[index].id != indexProduct) {
            clone.push(cart.value[index])
          }
        }
        await store.dispatch('shoppingCart/save', clone)
        LocalStorage.set('shoppingCart', clone)
      } catch (e) {
        throw e
      }
    }

    const getTotalProducts = () => {
      let total = 0
      cart.value.forEach(element => {
        total = parseInt(total) + parseInt(element.quantity)
      })
      return total
    }
    return {
      sidebarCart,
      cart,
      me,
      purchase,
      saveChangeCart,
      deleteProductCart,
      getTotalProducts
    }
  }
}
</script>

<style lang="scss" scoped>
.ms-sidebar-cart {
  width: 100%;
  height: 100%;

  .fade {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 2000;
    will-change: opacity;
  }

  .sidebar {
    background-color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    max-width: 460px;
    z-index: 5999;
    box-shadow: 0px 0px 20px #0e253629;
    will-change: transform;
    @include flex(flex-start, flex-start, column);
    @media (max-width: $breakpoint-sm-max) {
      max-width: none;
    }

    .sidebar-content {
      overflow: auto;
      width: 100%;
      flex-grow: 1;
    }

    .sidebar-header {
      padding: 35px 40px;
      border-bottom: 1px solid #ebf2f8;
      @media (max-width: $breakpoint-sm-max) {
        display: flex;
        padding: 18px 20px;
        height: 60px;
        width: 100%;
      }
      @media (max-width: '335px') {
        padding: 18px 10px;
      }

      .btn-close {
        padding: 0 5px;
        @include font-primary(14px, 'regular', 25px);
        margin-bottom: 15px;
        @media (max-width: $breakpoint-sm-max) {
          margin: 0px auto 0 0;
        }

        i {
          font-size: 14px;
          margin-right: 10px;
        }

        i:before {
          color: $primary;
        }
      }

      p {
        @include font-primary(18px, 'regular', 25px);
        color: #3b5b73;
        @media (max-width: $breakpoint-sm-max) {
          @include font-primary(16px, 'regular', 25px);
        }
        b {
          @include font-primary(18px, 'bold', 25px);
          color: #0e2536;
          @media (max-width: $breakpoint-sm-max) {
            @include font-primary(16px, 'bold', 25px);
          }
        }
      }
    }

    .sidebar-footer {
      width: 100%;
      padding: 35px 40px;
      background-color: #fff;
      border-top: 1px solid #ebf2f8;
      flex-shrink: 0;
      @media (max-width: '399px') {
        padding: 16px 24px;
      }

      p {
        @include font-primary(14px, 'regular', 20px);
        color: #3b5b73;
        margin-bottom: 15px;
      }

      .btn {
        padding: 10px 15px;
        border-radius: 5px;

        &.next {
          @include font-primary(14px, 'bold', 23px);
          margin-right: 10px;
          @media (max-width: '352px') {
            margin-bottom: 10px;
            margin-right: 0px;
            width: 100%;
          }
        }

        &.prev {
          @include font-primary(14px, 'regular', 23px);
          box-shadow: 0px 3px 6px #0000001a;
          @media (max-width: '352px') {
            width: 100%;
          }
        }
      }
    }
  }
  .ios-padding {
    padding: 60px 40px 40px !important;
  }
}
</style>
