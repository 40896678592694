export function agcoSelect(state) {
  return state.agcoSelect
}

export function sidebarCart({ sidebarCart }) {
  return sidebarCart
}

export function shoppingData(state) {
  return state.shoppingData
}

export function dialogLogin(state) {
  return state.dialogLogin
}

export function dialogRegister(state) {
  return state.dialogRegister
}

export function dialogConfirmAccount(state) {
  return state.dialogConfirmAccount
}

export function dialogMenuMobile(state) {
  return state.dialogMenuMobile
}

export function dialogForgotPassword(state) {
  return state.dialogForgotPassword
}

export function dialogForgotPasswordValidate(state) {
  return state.dialogForgotPasswordValidate
}

export function dialogResetConfirm(state) {
  return state.dialogResetConfirm
}

export function dialogHelp(state) {
  return state.dialogHelp
}

export function dialogCheckEmail(state) {
  return state.dialogCheckEmail
}

export function loginData(state) {
  return state.loginData
}

export function tax(state) {
  return state.tax
}

export function appIsLoading(state) {
  return state.appIsLoading
}
