import { api } from 'boot/axios.js'

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/customers',
      method: 'POST',
      data: data
    })
    ctx.commit('customer', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function validateAccount(ctx, data) {
  try {
    const response = await api({
      url: `/auth/verify-account/${data}`,
      method: 'GET',
    })
    return response
  } catch (e) {
    return e
  }
}

