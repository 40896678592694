import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/categories',
      params: queryParams,
      method: 'get'
    })
    const items = response.data.items
    const treeLines = []
    const lines = items.filter(c => c.parentId === null)
    for (const line of lines) {
      let cloneLine = JSON.parse(JSON.stringify(line))
      cloneLine.children = []
      const filteredCategories = items.filter(
        c => c.parentId === cloneLine.id
      )
      for (const category of filteredCategories) {
        let cloneCategory = JSON.parse(JSON.stringify(category))
        cloneCategory.children = []
        const subcategories = items.filter(
          c => c.parentId === cloneCategory.id
        )
        for (const subcategory of subcategories) {
          const cloneSubcategory = JSON.parse(JSON.stringify(subcategory))
          cloneCategory.children.push(cloneSubcategory)
        }
        cloneLine.children.push(cloneCategory)
      }
      treeLines.push(cloneLine)
    }
    ctx.commit('items', items)
    ctx.commit('pagination', response.data.pagination)
    ctx.commit('tree', treeLines)
    return response
  } catch (e) {
    return e
  }
}

export async function allCategories(ctx, data) {
  try {
    ctx.commit('allCategories', data)
  } catch (e) {
    return e
  }
}

export async function saveMenuCategories(ctx, data) {
  try {
    ctx.commit('menuCategory', data)
    return response
  } catch (e) {
    return e
  }
}

export async function cleanCategorySelection(ctx, data) {
  try {
    ctx.commit('clean', data)
    return response
  } catch (e) {
    return e
  }
}

export async function isClean(ctx, data) {
  try {
    ctx.commit('isClean', data)
    return response
  } catch (e) {
    return e
  }
}
