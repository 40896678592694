export function save(ctx, data) {
  let branch = []
  let others = []
  data.forEach(item => {
    if (item.quantity <= item.stock.branch) {
      branch.push(item)
    } else {
      others.push(item)
    }
  })
  ctx.commit('cart', data)
  ctx.commit('cartBranch', branch)
  ctx.commit('cartOthers', others)
}

export function cartBranch(ctx, data) {
  ctx.commit('cartBranch', data)
}

export function cartOthers(ctx, data) {
  ctx.commit('cartOthers', data)
}

export function totalCartBranch(ctx, data) {
  ctx.commit('totalCartBranch', data)
}

export function totalCartOthers(ctx, data) {
  ctx.commit('totalCartOthers', data)
}

export function stock(ctx, data) {
  ctx.commit('stock', data)
}
