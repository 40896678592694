export function item(state, data) {
  state.item = data
}

export function items(state, data) {
  state.items = data
}

export function promotionProducts(state, data) {
  state.promotionProducts = data
}

export function promotionProductsPagination(state, data) {
  state.promotionProductsPagination = data
}