import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/products',
      params: {
        ...queryParams
      },
      method: 'get'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    ctx.commit('brands', response.data.brands)
    ctx.commit('priceRange', {
      max: response.data.maxPrice,
      min: response.data.minPrice
    })
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('products', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function empty(ctx, queryParams = {}) {
  try {
    ctx.commit('items', [])
    ctx.commit('pagination', {})
  } catch (e) {
    return e
  }
}

export async function queryHome(ctx) {
  try {
    let products = [[], [], []]
    const resCat = await api({
      url: `categories`,
      params: {
        'filters[whereNull]': 'parentId',
        limit: 100
      },
      method: 'GET'
    })
    let lineIds = resCat.data.items.map(c => c.id)
    let i = 0
    while (i < 5) {
      const lineId = lineIds[Math.floor(Math.random() * lineIds.length)]
      const response = await api({
        url: `products`,
        params: {
          limit: 5,
          'filters[where][imageAvailable][eq]': true,
          'filters[where][categoryId][eq]': lineId,
          'filters[orWhere][path][sw]': `${lineId},`,
          'filters[orWhere][path][eq]': lineId
        },
        method: 'GET'
      })
      if (response.data.items.length > 0) {
        let j = 0
        while (j < 3) {
          const random = Math.floor(Math.random() * response.data.items.length)
          if (response.data.items[random].imageAvailable) {
            products[j].push(response.data.items[random])
            j++
          }
        }
        lineIds = lineIds.filter(l => l != lineId)
        i++
      } else {
        lineIds = lineIds.filter(l => l != lineId)
      }
    }
    ctx.commit('itemsHome', products)
  } catch (e) {
    return e
  }
}

export function isFavorite(ctx, data) {
  ctx.commit('isFavorite', data)
}

export async function quote(ctx, data) {
  try {
    const response = await api({
      url: `product-quote`,
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    throw e
  }
}


function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
