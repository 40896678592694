import { api } from 'boot/axios.js'

export async function me(ctx) {
  try {
    const response = await api({
      url: '/auth/me',
      method: 'GET'
    })
    ctx.commit('me', response.data)
    return response
  } catch (e) {
    return e
  }
}

export function logout(ctx) {
  ctx.commit('me', {})
}
