import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/invoices',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/invoices',
      method: 'POST',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await api({
      url: `/invoices/${data.id}`,
      method: 'PUT',
      data: data
    })
    return response
  } catch (e) {
    return e
  }
}

export async function remove(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('invoices', queryParams),
      data: queryParams,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}


function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
