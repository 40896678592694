export function agcoSelect({ commit }, data) {
  commit('agcoSelect', data)
}

export function sidebarCart({ commit }, data) {
  commit('sidebarCart', data)
}

// menu de detalle de compra en movil

export function shoppingData({ commit }, data) {
  commit('shoppingData', data)
}

export function dialogLogin({ commit }, data) {
  commit('dialogLogin', data)
}

export function dialogRegister({ commit }, data) {
  commit('dialogRegister', data)
}

export function dialogConfirmAccount({ commit }, data) {
  commit('dialogConfirmAccount', data)
}

export function dialogMenuMobile({ commit }, data) {
  commit('dialogMenuMobile', data)
}

export function dialogForgotPassword({ commit }, data) {
  commit('dialogForgotPassword', data)
}

export function dialogForgotPasswordValidate({ commit }, data) {
  commit('dialogForgotPasswordValidate', data)
}

export function dialogResetConfirm({ commit }, data) {
  commit('dialogResetConfirm', data)
}

export function dialogHelp({ commit }, data) {
  commit('dialogHelp', data)
}

export function dialogCheckEmail({ commit }, data) {
  commit('dialogCheckEmail', data)
}

export function loginData({ commit }, data) {
  commit('loginData', data)
}

export function appIsLoading({ commit }, data) {
  commit('appIsLoading', data)
}
