import { boot } from 'quasar/wrappers'
import { Cookies } from 'quasar'

let getCookies = null

// you need access to `ssrContext`
export default boot ((ssrContext) => {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies // otherwise we're on client
  // "cookies" is equivalent to the global import as in non-SSR build
  getCookies =  cookies
})

export { getCookies }
