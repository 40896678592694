<template>
  <div class="ms-sidebar-cart-item">
    <div class="cart-item-wrapper">
      <div class="img-wrapper">
        <q-img
          :src="
            data.product.imageAvailable
              ? data.url
              : require('src/assets/sin_imagen.png')
          "
          :alt="data.product.name"
          fit="contain"
          class="img-product"
          spinner-color="primary"
        />
      </div>
      <div class="info-wrapper">
        <p class="name">
          {{ data.product.name }}
        </p>
        <p class="sku">Parte {{ data.product.partNumber }}</p>
        <div class="counter-wrapper">
          <ms-count
            :model-value="data.quantity"
            :min="1"
            :max="data.stock.total"
            @update:model-value="onUpdateQuantity"
          />
        </div>
        <div
          class="full-width"
          :class="$q.screen.width < 600 ? 'flex' : 'column'"
        >
          <p v-if="data.product.promotion" class="old-price">
            {{ $filters.currency(prices.oldPrice) }}
          </p>
          <p class="price">
            {{ $filters.currency(prices.currentPrice) }}
          </p>
          <p class="legend">Los precios incluyen IVA</p>
          <q-btn
            unelevated
            flat
            dense
            no-caps
            color="primary"
            class="btn-remove"
            @click="removeProductCart(data.product)"
          >
            <i class="icon-delete" />
            Eliminar
          </q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *
 * MsSidebarCartItem Component
 *
 * @author Emmanuel Hernández
 * @copyright 2021, Marciano Studio S.C.
 */
import useUtils from 'src/composables/use-utils'

import MsCount from 'components/_shared/ms-count.vue'

export default {
  name: 'MsSidebarCartItem',
  components: {
    MsCount
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const { getPrices, modifyQtyProductCart, removeProductCart } = useUtils()

    const prices = getPrices(props.data.product)

    const onUpdateQuantity = val => {
      modifyQtyProductCart(props.data.product, val)
    }

    return {
      prices,
      onUpdateQuantity,
      removeProductCart
    }
  }
}
</script>

<style lang="scss" scoped>
.ms-sidebar-cart-item {
  padding: 30px 45px;
  border-bottom: 1px solid #ebf2f8;
  @media (max-width: $breakpoint-xs-max) {
    padding: 16px 20px 24px;
  }

  &:last-child {
    border-bottom: none;
  }

  .cart-item-wrapper {
    @include flex(flex-start, flex-start);

    .img-wrapper {
      width: 140px;
      height: 140px;
      margin-right: 20px;
      flex-shrink: 0;
      @media (max-width: $breakpoint-xs-max) {
        width: 80px;
        height: 80px;
      }

      .img-product {
        width: 100%;
        height: 100%;
      }
    }

    .info-wrapper {
      @media (max-width: $breakpoint-xs-max) {
        width: 100%;
      }
      .name {
        @include font-primary(18px, 'regular', 30px);
        color: #0e2536;
        @media (max-width: $breakpoint-xs-max) {
          @include font-primary(14px, 'regular', 20px);
        }
      }

      .sku {
        @include font-primary(14px, 'medium', 23px);
        color: #87a3b8;
        @media (max-width: $breakpoint-xs-max) {
          @include font-primary(12px, 'medium', 19px);
        }
      }

      .counter-wrapper {
        margin-bottom: 10px;
      }

      .price {
        @include font-primary(18px, 'regular', 24px);
        color: #0e2536;
        margin-bottom: 5px;
        @media (max-width: $breakpoint-xs-max) {
          @include font-primary(16px, 'regular', 24px);
          color: #0e2536;
          margin-right: auto;
        }
      }
      .old-price {
        @include font-primary(14px, 'regular', 20px);
        color: #93a0aa;
        text-decoration: line-through;
        margin-bottom: 5px;
      }
      .legend {
        @include font-primary(12px, 'regular', 16px);
        color: #93a0aa;
        margin-bottom: 10px;
      }

      .btn-remove {
        @include font-primary(12px, 'regular', 19px);
        padding: 0 5px;
        width: fit-content;

        i {
          font-size: 12px;
          margin-right: 10px;

          &:before {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
