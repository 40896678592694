const errors = {
  DEFAULT:
    'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.',
  500: {
    DEFAULT:
      'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.'
  },
  401: {
    DEFAULT: 'Debe estar autenticado para poder acceder a este recurso',
    AUTH_TOKEN_COMPROMISED:
      'Su sesión ha sido usada en otro dispositivo y ha sido desactivada. Vuelva a iniciar sesión para ingresar nuevamente a la plataforma.',
    AUTH_INVALID_CREDENTIALS: 'Usuario y/o contraseña incorrectos'
  },
  404: {
    DEFAULT: 'El recurso no fue encontrado en el servidor',
    ORDER_NOT_FOUND: 'La orden no existe.',
    PAYMENT_NOT_FOUND: 'El método de pago que elegiste no existe.',
    102: 'Lo siento, no se puede encontrar la sucursal seleccionada.'
  },
  422: {
    DEFAULT: 'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.',
    YOU_HAVE_CREATED_AN_ORDER: 'Ya cuentas con una orden creada.',
    SESSION_EXPIRED: 'El tiempo de registro ha expirado.',
    AVAILABILITY_NOT_FOUND: 'La selección no concuerda.',
    UNAUTHORIZED: 'No estás autorizado para realizar esta acción.',
    INVALID_SESSION: 'La sesión actual no existe o es inválida.',
    INVALID_TOKEN: 'El código no es válido, por favor verifica que tu código sea correcto.',
    FAVORITE_PRODUCT_EXIST: 'Este producto ya se encuentra en tu lista de favoritos.',
    100: {
      DEFAULT: 'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.',
      email: 'El correo electrónico ya se encuentra registrado.',
      token: 'El código no es válido, por favor verifica que tu código sea correcto.',
      rfc: 'Tu RFC no es válido.',
      zipcode: 'Tu código postal no es válido.'
    }
  }
}

const getError = (status, code = 'DEFAULT', fields = []) => {
  if (fields.length > 0) {
    let _errors = []
    const _status = errors[status] ? status : 500
    const _code = 100
    for (const field of fields) {
      _errors.push(errors[_status][_code][field.field])
    }
    return _errors
  }
  const _status = errors[status] ? status : 500
  const _code = errors[_status][code] ? code : 'DEFAULT'
  return errors[_status][_code]
}

export default getError
