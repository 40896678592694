export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}

export function item(state) {
  return state.item
}

export function itemsHome(state) {
  return state.itemsHome
}

export function brands(state) {
  return state.brands
}

export function priceRange(state) {
  return state.priceRange
}

export function isFavorite(state) {
  return state.isFavorite
}
