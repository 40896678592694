import { Notify } from 'quasar'

const useNotify = () => {
  const error = (message, position = 'top') => {
    Notify.create({
      color: 'red-5',
      message,
      position,
      icon: 'eva-alert-circle-outline',
      actions: [{ icon: 'eva-close', handler: Function, color: 'red-3' }]
    })
  }

  const success = (message, position = 'top') => {
    Notify.create({
      color: 'green-6',
      message,
      position,
      icon: 'eva-checkmark',
      actions: [{ icon: 'eva-close', handler: Function, color: 'green-3' }]
    })
  }

  return {
    error,
    success
  }
}

export default useNotify
