export function item(state) {
  return state.item
}

export function items(state) {
  return state.items
}

export function pagination(state) {
  return state.pagination
}

export function tree(state) {
  return state.tree
}

export function menuCategory(state) {
  return state.menuCategory
}
export function clean(state) {
  return state.clean
}
export function allCategories(state) {
  return state.allCategories
}
export function isClean(state) {
  return state.isClean
}
