export default function () {
  return {
    sidebarCart: false,
    agcoSelect: false,
    shoppingData: false,
    dialogLogin: false,
    dialogRegister: false,
    dialogConfirmAccount: false,
    dialogMenuMobile: false,
    dialogHelp: false,
    dialogForgotPassword: false,
    dialogForgotPasswordValidate: false,
    dialogResetConfirm: false,
    dialogCheckEmail: false,
    loginData: {},
    tax: 0.16,
    appIsLoading: false
  }
}
