export function customer(state, data) {
  state.customer = data
}

export function items(state, data) {
  state.items = data
}
export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function updateProfile(state, data) {
  state.updateProfile = data
}
