import { Loading } from 'quasar'
import { boot } from 'quasar/wrappers'

export default boot(({ router, store, redirect }) => {
  router.beforeEach((to, from, next) => {
    if (!process.env.SERVER) {
      window.scrollTo(0, 0)
    }
    if (store.getters['auth/isAuthenticated']) {
      store.dispatch('user/me')
    } else if (to.name === 'my-shopping-cart' || to.name === 'order-confirmation') {
      Loading.show()
      redirect({ name: 'home' })
    }
    if (from.name != 'product-detail' || to.name != 'product-detail') {
      Loading.show()
    } else {
      store.dispatch('app/appIsLoading', true)
    }
    next()
  })

  router.afterEach(() => {
    store.dispatch('app/appIsLoading', false)
    Loading.hide()
  })
})
