import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/warehouses',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('total', parseInt(response.data.branch) + parseInt(response.data.others))
    return response.data
  } catch (e) {
    return e
  }
}
