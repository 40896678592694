export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  state.item = data
}

export function brands(state, data) {
  state.brands = data
}

export function priceRange(state, data) {
  state.priceRange = data
}

export function isFavorite(state, data) {
  state.isFavorite = data
}

export function itemsHome(state, data) {
  state.itemsHome = data
}
