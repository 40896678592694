import { api } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: '/delivery-addresses',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, data) {
  try {
    const response = await api({
      url: `/delivery-addresses/${data}`,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function save(ctx, data) {
  try {
    const response = await api({
      url: '/delivery-addresses',
      method: 'POST',
      data: data
    })
    // ctx.commit('items', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function update(ctx, data) {
  try {
    const response = await api({
      url: `/delivery-addresses/${data.id}`,
      method: 'PUT',
      data: data
    })
    // ctx.commit('items', response.data)
    // ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function remove(ctx, queryParams = {}) {
  try {
    const response = await api({
      url: parseUrl('delivery-addresses', queryParams),
      data: queryParams,
      method: 'DELETE'
    })
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return `/${state}/${params.id}`
  }
  return `/${state}`
}
