export default function () {
  return {
    item: {},
    items: [],
    pagination: {},
    products: {
      branch: [],
      others: []
    }
  }
}
